<div
  #tabsContainer
  class="tab-selector"
>
  <div
    [style.padding-inline.px]="inlinePadding()"
    class="tab-selector__items"
  >
    @for (tab of tabs(); track tab.id) {
      <div
        #tab
        (click)="selectTab(tab)"
        (keydown.enter)="selectTab(tab)"
        (ccResize)="recalculateUnderlineParams.notify()"
        [attr.data-tab-id]="tab.id"
        [class.tab-selector__items-item--disabled]="tab.disabled"
        [class.tab-selector__items-item--selected]="selectedTab().id === tab.id"
        class="tab-selector__items-item"
        tabindex="0"
        [ccAutoAnimate]
      >
        <div
          [attr.data-text]="tab.label"
          class="tab-selector__items-item-title"
        >
          {{ tab.label || tab.labelTranslationKey | translate }}
        </div>

        @if (tab.count !== undefined) {
          <div class="tab-selector__items-item-count">
            {{ tab.count }}
          </div>
        }
      </div>
    }
  </div>

  <div class="tab-selector__underline-container">
    <div
      #underline
      class="tab-selector__underline-container-line"
    ></div>
  </div>
</div>
